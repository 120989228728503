<template>
    <div v-loading.fullscreen.lock="loading">
        <div class="header-section">
            <div class="login-logo mb-4">
                <img class="logo_img" :src="`/img/logos/${$currentPlatform}_logo.png`"/>
            </div>
            <div class="toplogin-btn">
                <router-link style="width: 100px;" class="base-button btn btn-default login-text px-2" to="/login">Login</router-link>
            </div>
            <div class="toplogin-btn">
                <router-link style="width: 100px;"  class="base-button btn btn-default mr-2 px-2 signup-text" to="/signup">Sign up</router-link>
            </div>
        </div>
        <section class="banner-section">
            <div class="container">
                <div class="banner-caption">
                    <h2>DRA Training Platform</h2>
                    <p>Industry training solutions to fit the needs of current or future restaurant employees.</p>
                </div>
            </div>
        </section>
        <div class="content-area" style="padding-top: 20px">
            <div class="container-fluid">
                <h5 class="mb-5 text-center">Sign up today to earn position-specific certificates, or complete individual trainings for required courses like Online Alcoholic Beverage Server Training.</h5>
                <div class="row">
                    <div class="col-lg-5 form-section custom-form">
                        <validation-observer v-slot="{ handleSubmit }" ref="formValidator" class="custom-bg-mobil">
                            <form role="form" @submit.prevent="handleSubmit()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4 style="color: #444c57" class="">Your Details</h4>
                                        <hr/>
                                    </div>
                                    <br/>
                                </div>
                                <div class="form-row">
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Company Name </label>
                                        <base-input type="text" name="Company name" placeholder="Company Name" v-model="employee.company_name"></base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">First Name <span class="req"> *</span></label>
                                        <base-input type="text" name="First name" placeholder="First Name" rules="required" v-model="employee.first_name"></base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Last Name <span class="req"> *</span></label>
                                        <base-input type="text" name="Last name" placeholder="Last Name" rules="required" v-model="employee.last_name"></base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Email <span class="req"> *</span></label>
                                        <base-input type="email" name="Email" placeholder="Email" rules="required" v-model="employee.email"></base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Phone <span class="req"> *</span></label>
                                        <base-input name="Telephone" placeholder="Phone" rules="required" v-model="employee.phone" @input="acceptNumber"></base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Address <span class="req"> *</span></label>
                                        <base-input type="text" name="Address" placeholder="Address" v-model="employee.address"></base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">City <span class="req"> *</span></label>
                                        <base-input type="text" name="City" placeholder="City" v-model="employee.city"></base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">State <span class="req"> *</span></label>
                                        <base-input type="text" name="State" placeholder="State" v-model="employee.state"></base-input>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                                        <label class="form-control-label">Zip Code <span class="req"> *</span></label>
                                        <base-input type="number" name="Zip code" placeholder="Zip Code" v-model="employee.zipcode"></base-input>
                                    </div>
                                </div>
                                <hr/>
                                <div class="row" v-if="lead_id">
                                    <h3 style="color: #444c57" class="mt-2 ml-2">Login Information</h3>
                                </div>
                                <hr v-if="lead_id"/>
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <label class="form-control-label">Username <span class="req"> *</span></label>
                                        <base-input type="test" name="Username" placeholder="Username" readonly v-model="employee.email"></base-input>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-control-label">Password <span class="req"> *</span></label>
                                        <base-input :type="passwordFieldType" name="Password" placeholder="Password" v-model="employee.password"></base-input>
                                        <div class="password-eye passwordview">
                                            <span @click.prevent="switchVisibility"><i class="fa fa-eye" title="Show Password"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </validation-observer>
                    </div>
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col-md-12 mt-0 text-center bg-gray-100 p-2 shadow-sm">
                                <h5 class="mb-0 new-signup">
                                    Not a member,
                                    <a class="linkColor" target="_blank" href="https://www.delawarerestaurant.org/membership/"> click here </a>
                                    <span>to join.</span>
                                </h5>
                            </div>
                            <div class="col-md-12">
                                <div class="row priceGrid custom-bg" style="font-weight: bolder">
                                    <div class="col-lg-6 col-md-6 col-sm-8 col-8 price-info">Courses</div>
                                    <div class="col-lg-6 col-md-3 col-sm-2 col-2 price-info text-end">Pricing</div>
                                    <!--                                    <div class="col-lg-3 col-md-3 col-sm-2 col-2 price-info text-center">Non-Member Pricing</div>-->
                                </div>
                                <el-checkbox-group v-model="checked_courses">
                                    <span class="odd-row" v-for="option in course" :key="option.id">
                                        <div class="row priceGrid">
                                            <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                                                <el-checkbox :label="option.id">{{ option.course_name }}</el-checkbox>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-2 col-2 text-end">
                                                <span class="price-info">${{ option.member_price }}</span>
                                            </div>
                                            <!--                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">-->
                                            <!--                                                <span class="price-info">${{ option.non_member_price }}</span>-->
                                            <!--                                            </div>-->
                                        </div>
                                        <br/>
                                    </span>
                                </el-checkbox-group>
                                <el-checkbox-group v-model="checked_coursefolders">
                                    <span class="odd-row" v-for="option in coursefolders" :key="option.id">
                                        <div class="row priceGrid">
                                            <div class="col-md-10">
                                                <el-popover :ref="`fromPopOver${option.id}`" placement="top-start" width="500" trigger="hover" :key="option.id" :id="option.id">
                                                    <div class="provideScroller">
                                                        <ul>
                                                            <li v-for="(courses, index) in option.attachedCourses" :key="index">
                                                                {{ courses.name }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </el-popover>
                                                <el-checkbox :label="option.id">
                                                    <span>{{ option.coursefolder_name }}</span>
                                                </el-checkbox>
                                                <span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span>
                                            </div>
                                            <div class="col-md-2 text-end">
                                                <span class="price-info">${{ option.member_price }}</span>
                                            </div>
                                            <!--                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">-->
                                            <!--                                                <span class="price-info">${{ option.non_member_price }}</span>-->
                                            <!--                                            </div>-->
                                            <br/>
                                        </div>
                                    </span>
                                </el-checkbox-group>
                                <el-checkbox-group v-model="checked_other_courses">
                                    <span class="odd-row" v-for="option in basicPackage1" :key="option.id">
                                        <div class="row priceGrid">
                                            <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                                                <el-popover :ref="`fromPopOver${option.id}`" placement="top-start" width="500" trigger="hover" :key="option.id" :id="option.id">
                                                    <div class="provideScroller">
                                                        <ul>
                                                            <li v-for="(courses, index) in option.attachedCourses" :key="index">
                                                                {{ courses.name }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </el-popover>
                                                <el-checkbox :label="option.id">{{ option.coursefolder_name }}</el-checkbox
                                                ><span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                                                <span class="price-info">${{ option.member_price }}</span>
                                            </div>
                                            <!--                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">-->
                                            <!--                                                <span class="price-info">${{ option.non_member_price }}</span>-->
                                            <!--                                            </div>-->
                                        </div>
                                    </span>
                                </el-checkbox-group>
                                <el-checkbox-group v-model="checked_other_courses">
                                    <span class="odd-row" v-for="option in basicPackage2" :key="option.id">
                                        <div class="row priceGrid">
                                            <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                                                <el-popover :ref="`fromPopOver${option.id}`" placement="top-start" width="500" trigger="hover" :key="option.id" :id="option.id">
                                                    <div class="provideScroller">
                                                        <ul>
                                                            <li v-for="(courses, index) in option.attachedCourses" :key="index">
                                                                {{ courses.name }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </el-popover>
                                                <el-checkbox :label="option.id" v-if="checked_coursefolders.length > 0" disabled>{{ option.coursefolder_name }}</el-checkbox>
                                                <el-checkbox :label="option.id" v-else-if="checked_coursefolders.length == 0">{{ option.coursefolder_name }}</el-checkbox
                                                ><span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                                                <span class="price-info">${{ option.member_price }}</span>
                                            </div>
                                        </div>
                                        <br/>
                                    </span>
                                </el-checkbox-group>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="row" v-for="(promoCode, index) in promoCodes" :key="index">
                            <div class="col-md-10">
                                <div class="form-group promo_inputBOX">
                                    <label>Promo Code
                                        <input type="text" class="form-control" id="promo-code" placeholder="Promo Code" v-model="promoCodes[index]">
                                    </label>
                                </div>
                                <p class="paragraph-tag">If you have not received a promo code for the alcohol or drug awareness course, please <a href="https://drapromos.com/">click here.</a></p>
                            </div>
                            <div class="col-md-2">
                                <div class="promoBTN_BOX">
                                    <button class="btn promoBTN_Minus" v-if="(promoCodes.length > 1)" @click="removePromoCode(index)">-</button>
                                    <button class="btn promoBTN" v-if="promoCodes.length === (index + 1)" @click="addPromoCode">+</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <base-button class="btn-success" @click.prevent="applyPromoCode">Apply Coupon</base-button>
                        </div>
                        <div class="row">
                            <div class="col-md-12 mt-4" v-if="showDiscountDetail">
                                <div class="row payable-content">
                                    <div class="col-md-12 col-12 mb-4">
                                        <span class="promocode-applied" v-if="promoCodeDetails.type == 'percentage'">Promotional Discount Applied ({{ promoCodeDetails.discount_percentage }}%)</span>
                                        <span class="promocode-applied" v-if="promoCodeDetails.type == 'fixed_price'">Promotional Discount Applied(${{ promoCodeDetails.discount_fixed_price }})</span>
                                    </div>
                                    <div class="col-md-6 col-6">
                                        <label class="form-control-label">Course Cost:</label>
                                    </div>
                                    <div class="col-md-6 col-6 text-right">
                                        <label class="form-control-label">{{ formatPrice(sub_total) }}</label>
                                    </div>
                                </div>
                                <div class="row payable-content">
                                    <div class="col-md-6 col-6">
                                        <label class="form-control-label">Minus Discount:</label>
                                    </div>
                                    <div class="col-md-6 col-6 text-right">
                                        <label class="form-control-label">-{{ formatPrice(total_discount) }}</label>
                                    </div>
                                </div>
                                <div class="row payable-content">
                                    <div class="col-md-6 col-6">
                                        <label class="form-control-label" style="text-decoration:underline; font-weight:bold;">Total Amount Due:</label>
                                    </div>
                                    <div class="col-md-6 col-6 text-right">
                                        <label class="form-control-label" style="text-decoration:underline; font-weight:bold;">{{ formatPrice(total_cost) }}</label>
                                    </div>
                                </div>
                            </div>
                            <div ref="move-to"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 offset-md-5">
                        <div class="text-center pb-3" v-if="(checked_courses.length || checked_coursefolders.length || checked_other_courses.length) && !lead_id">
                            <base-button class="mt-2" v-if="showReEstimatButton" @click="reEstimatePrice">Re-estimate</base-button>
                            <base-button class="mt-2" @click.prevent="checkIfCRAMember" :disabled="disableSignUpButton">Sign Up</base-button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 offset-md-5">
                        <serv-safe-food-handler/>
                    </div>
                </div>
            </div>
        </div>
        <modal :show.sync="agreementModal" class="user-modal">
            <h3 slot="header">Terms and Conditions</h3>
            <form>
                <div class="agreement-content">
                    <agreement type="individual"></agreement>
                </div>
                <div class="text-center mt-2">
                    <base-button type="success" @click.prevent="finalCreateAccount"> I Agree</base-button>
                    <base-button type="danger" @click.prevent="cancelAgreement"> Cancel</base-button>
                </div>
            </form>
        </modal>
        <modal :show.sync="showPaymentOption">
            <h4 slot="header" style="color: #444c57" class="modal-title mb-0">Payment</h4>
            <credit-card v-if="showPaymentOption" type="individual" :monthlyAmount="total_cost" :yearlyAmount="total_cost" :city="employee.city" :state="employee.state" :address="employee.address" :zip="employee.zipcode" :enablePaymentButton="enablePaymentButton" v-on:payClicked="payClicked"/>
        </modal>

        <modal :show.sync="paymentModalMessage">
          <h1 class="text-capitalize text-center text-strong m-0 payment-modal-text text-danger font-weight-500">Your payment is processing...</h1>
      </modal>

        <the-footer/>
    </div>
</template>
<script>
import {Checkbox, CheckboxGroup, Option, OptionGroup, Select, Table, TableColumn} from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import CreditCard from "@/views/Widgets/CreditCard";
import Agreement from "./Agreement.vue";
import {Dynamic} from "../../wl";
import TheFooter from '@/views/Layout/AppFooter.vue';
import ServSafeFoodHandler from './ServSafeFoodHandler.vue';

export default {
    name: "register",
    components: {
        Agreement,
        CreditCard,
        [Select.name]: Select,
        [Option.name]: Option,
        [OptionGroup.name]: OptionGroup,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        TheFooter,
        ServSafeFoodHandler,
    },
    data() {
        return {
            loading: false,
            paymentModalMessage: false,
            baseUrl: this.$baseUrl,
            agreementModal: false,
            already_member: false,
            member_id: "",
            courseFolderSelectionFocused: false,
            courseSelectionFocused: false,
            showPaymentOption: false,
            search: "",
            otherCourses: [],
            courses: [],
            course: "",
            coursefolders: [],
            checked_courses: [],
            basicPackage1: [],
            basicPackage2: [],
            checked_coursefolders: [],
            checked_other_courses: [],
            formData: {
                employee_first_name: "",
                employee_last_name: "",
                user_type: "",
                employee_address: "",
                employee_city: "",
                employee_state: "",
                employee_zipcode: "",
                employee_email: "",
                employee_phone_num: "",
                password: "",
                address: "",
                total_amount: 0,
                promo_code: '',
            },
            employee: {
                company_name: "",
                first_name: "",
                last_name: "",
                emial: "",
                phone: "",
                address: "",
                city: "",
                state: "",
                zipcode: "",
                username: "",
                password: "",
            },
            passwordFieldType: "password",
            showPricePlan: false,
            total_cost: "",
            total_discount: "",
            discountperlocation: "",
            perYearCost: "",
            discount_msg: "",
            lead_id: "",
            siteName: "",
            infoEmail: "",
            inputStyle: "",
            showPromoField: false,
            showReEstimatButton: false,
            disableSignUpButton: false,
            promo_code: '',
            isPromoCodeValid: false,
            showDiscountDetail: false,
            promoCodeDetails: {
                discount_percentage: 0,
                discount_fixed_price: 0,
                type: '',
            },
            isSignupButtonClicked: false,
            alcoholCourses: [],
            enablePaymentButton: false,
            promoCodes: [
                ''
            ],
        };
    },
    mounted() {

        this.siteName = Dynamic.SITE_NAME;
        this.infoEmail = Dynamic.INFO_EMAIL;
        if (this.$route.query.inner) {
            this.$gtag.event("S.H.P. Individual signup", {method: "Google"});
        } else if (this.$route.query.course_type) {
            this.$gtag.event("Sexual Harassment Prevention Individual signup", {
                method: "Google",
            });
        } else {
            this.$gtag.event("Individual signup", {method: "Google"});
        }
    },
    async created() {
        if (this.$route.query.redirection === "yes") {
            this.employee.first_name = localStorage.getItem("fname");
            this.employee.last_name = localStorage.getItem("lname");
            this.employee.email = localStorage.getItem("email");
            this.checked_courses = JSON.parse(localStorage.getItem("courses"));
        } else {
            localStorage.removeItem("fname");
            localStorage.removeItem("lname");
            localStorage.removeItem("email");
            localStorage.removeItem("courses");
        }
        await this.$http.get("user/discountRules").then((resp) => {
            for (let course of resp.data.courses) {
                if (course.course_type === 1) {
                    this.alcoholCourses.push(course.id);
                }
                let obj = {
                    checked: false,
                    id: course.id,
                    course_name: course.name,
                    member_price: course.member,
                    non_member_price: course.non_member,
                    course_type: course.course_type,
                    cost: course.cost,
                };
                this.courses.push(obj);
            }
            this.course = this.courses;

            for (let coursefolder of resp.data.course_folders) {
                let obj1 = {
                    id: coursefolder.id,
                    coursefolder_name: coursefolder.folder_name,
                    member_price: coursefolder.member,
                    non_member_price: coursefolder.non_member,
                    attachedCourses: coursefolder.active_courses,
                };
                this.coursefolders.push(obj1);
            }
        });
        if (this.$route.query.course_type) {
            this.checkdefaultSexualCourse();
        }
    },
    methods: {
        /*scrollToCoursesEnd(){
            const coord = this.$refs['move-to'].getBoundingClientRect();
            this.$refs['cousesContainer'].scrollTo({top: coord.x , left: coord.y , behavior:'smooth'})
        },*/
        courseFolderChecked(value) {
            if (this.checked_coursefolders.length > 0) {
                this.checked_coursefolders = [];
                this.checked_coursefolders.push(value);
                if (this.checked_coursefolders.length > 0) {
                    this.basicPackage2.forEach((chck) => {
                        this.checked_other_courses.pop(chck.id);
                    });
                }
            } else {
                this.checked_coursefolders = [];
            }
        },
        checkdefaultSexualCourse() {
            this.courses.forEach((chck) => {
                if (chck.course_type == 0 && chck.course_name == "Sexual Harassment Prevention (Employee)") {
                    this.checked_courses.push(chck.id);
                }
            });
        },
        finalCreateAccount() {
            this.agreementModal = false;
            if (this.total_cost != 0) {
                this.showPaymentOption = true;
            } else {
                this.createAccount(this.formData);
            }
        },
        showDone() {
            this.courseSelectionFocused = true;
        },
        doneClicked() {
            this.courseSelectionFocused = false;
        },
        showDoneButton() {
            this.courseFolderSelectionFocused = true;
        },
        doneButtonClicked(e) {
            this.courseFolderSelectionFocused = false;
        },
        cancelAgreement() {
            this.agreementModal = false;
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
        },
        formatPrice(value) {
            return "$" + Number(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        },
        acceptNumber() {
            var x = this.employee.phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.employee.phone = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        },
        showAgreement() {
            if (this.employee.password == "") {
                return Swal.fire({
                    title: "Error!",
                    text: "Please enter password to continue.",
                    icon: "error",
                });
            }
            this.formData = {
                employee_company_name: this.employee.company_name ? this.employee.company_name : "individual",
                employee_first_name: this.employee.first_name,
                employee_last_name: this.employee.last_name,
                user_type: "individual",
                employee_address: this.employee.address,
                employee_city: this.employee.city,
                employee_state: this.employee.state,
                employee_zipcode: this.employee.zipcode,
                employee_email: this.employee.email,
                employee_phone_num: this.employee.phone,
                password: this.employee.password,
                address: this.employee.address,
                courses: this.checked_courses,
                courseFolders: this.checked_coursefolders,
                otherCourseFolders: this.checked_other_courses,
                already_member: this.already_member,
                i_agree: true,
                promo_code: this.promo_code,
            };
            this.agreementModal = true;
        },
        checkIfCRAMember() {
            this.isSignupButtonClicked = true;
            this.signupClicked();
        },
        signupClicked() {
            this.loading = true;
            if (this.employee.first_name && this.employee.last_name && this.employee.address && this.employee.city && this.employee.state && this.employee.zipcode && this.employee.email) {
                this.$http
                    .post("user/lead", {
                        company_name: this.employee.company_name ? this.employee.company_name : "individual",
                        first_name: this.employee.first_name,
                        last_name: this.employee.last_name,
                        number_of_locations: 1,
                        number_of_employees: 1,
                        email: this.employee.email,
                        phone_num: this.employee.phone,
                        user_type: "individual",
                        already_member: this.already_member,
                        course_ids: this.checked_courses,
                        course_folders: this.checked_coursefolders,
                        other_courses: this.checked_other_courses,
                        promoCodes: this.promoCodes,
                        isPromoCodeValid: this.isPromoCodeValid,
                    })
                    .then((resp) => {
                        this.loading = false;
                        this.paymentModalMessage = false;
                        this.sub_total = resp.data.sub_total;
                        this.total_discount = resp.data.discount;
                        this.total_cost = resp.data.perYearCost;
                        this.showDiscountDetail = true;
                        if (this.isSignupButtonClicked === true) {
                            this.showAgreement();
                            this.isSignupButtonClicked = false;
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        return Swal.fire({
                            title: "Error!",
                            html: error.response.data.message,
                            icon: "error",
                        })
                    });
            } else {
                this.loading = false;
                Swal.fire({
                    title: "Error!",
                    text: "Please fill all mandatory fields.",
                    icon: "error",
                });
            }
        },
        payClicked(cardData) {
            this.paymentModalMessage = true;
            let payment = {
                payment_type: cardData.paymentType,
                cardholder_street_address: cardData.address + "," + cardData.city + "," + cardData.state,
                cardholder_zip: cardData.zip,
                transaction_amount: "",
                token: cardData.token,
            };
            if (payment.payment_type == "monthly") {
                payment.transaction_amount = this.total_cost.toFixed(2);
            }
            if (payment.payment_type == "yearly") {
                payment.transaction_amount = this.total_cost.toFixed(2);
            }
            this.formData.payment = payment;
            this.formData.employee_address = cardData.address;
            this.formData.address = cardData.address;
            this.formData.employee_state = cardData.state;
            this.formData.employee_city = cardData.city;
            this.formData.employee_zipcode = cardData.zip;
            this.formData.promoCodes = this.promoCodes;
            this.formData.total_amount = this.sub_total;
            this.createAccount(this.formData);
        },
        createAccount(formDataSubmitted) {
            this.loading = true;
            delete this.$http.defaults.headers["authorization"];
            formDataSubmitted.promoCodes = this.promoCodes
            formDataSubmitted.total_cost = this.sub_total;
            formDataSubmitted.total_cost = this.sub_total;
            this.$http
                .post("employees/register", formDataSubmitted)
                .then((resp) => {
                    let ids = [];
                    let obj = {
                        id: resp.data.id,
                    };
                    ids.push(obj);
                    this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                    this.paymentModalMessage = false;
                    Swal.fire({
                        title: "Success!",
                        html: `Your account has been created and is now active! <a href="/#/login">Click here</a> to Login`,
                        icon: "success",
                        confirmButton: "btn btn-success",
                        confirmButtonText: "Ok",
                    }).then((result) => {
                        this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                        this.paymentModalMessage = false;
                        if (result.value) {
                            this.$router.push("/login");
                        }
                    });
                }).catch((error) => {
                  this.paymentModalMessage = false;
                this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                return Swal.fire({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                });
            });
        },
        applyPromoCode() {
            this.signupClicked();
            // this.loading = true;
            // let data = {
            //     amount: this.sub_total,
            //     promocode: this.promo_code,
            // }
            // this.$http.post("promocode/apply_promocode", data).then(resp => {
            //     this.sub_total = resp.data.final_amount;
            //     this.course_cost = resp.data.previous_amount;
            //     this.discounted_cost = resp.data.discounted_amount;
            //     this.discount_percentage = resp.data.discount_percentage;
            //     this.promoCodeDetails.discount_percentage = resp.data.discount_percentage;
            //     this.promoCodeDetails.discount_fixed_price = resp.data.discount_fixed_price;
            //     this.promoCodeDetails.type = resp.data.discount_type;
            //     this.loading = false;
            //     this.isPromoCodeValid = true;
            //     this.signupClicked();
            // }).catch((error) => {
            //     this.loading = false;
            //     this.isPromoCodeValid = false;
            //     return Swal.fire({
            //         title: "Error!",
            //         html: error.response.data.message,
            //         icon: "error"
            //     });
            // });
        },
        reEstimatePrice: function () {
            this.showReEstimatButton = !this.showReEstimatButton;
            this.disableSignUpButton = false;
            this.isSignupButtonClicked = false;
            this.signupClicked();
        },
        addPromoCode: function () {
            this.promoCodes.push('');
        },
        removePromoCode: function (index) {
            this.promoCodes.splice(index, 1);
        }
    },
    watch: {
        checked_courses: function () {
            this.showPromoField = false;
            this.alcoholCourses.forEach((id) => {
                if (this.checked_courses.includes(id) === true) {
                    this.showPromoField = true;
                }
            });
            this.showReEstimatButton = true;
            this.disableSignUpButton = true;
            if (this.checked_courses.length == 1) {
                this.showReEstimatButton = false;
                this.disableSignUpButton = false;
            }
            // this.scrollToCoursesEnd();
        },
        checked_coursefolders: function () {
            this.showReEstimatButton = true;
            this.disableSignUpButton = true;
            if (this.checked_coursefolders.length == 1) {
                this.showReEstimatButton = false;
                this.disableSignUpButton = false;
            }
            // this.scrollToCoursesEnd();
        },
    }
};
</script>
<style>

.payment-modal-text {
    animation: textBlink 2s linear infinite;
}
.btn-default {
    background-color: #a8312d;
    border-color: #a8312d;
    padding: 8px 10px ;
    font-size: 14px;
}
@keyframes textBlink{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

.form-section {
    background-color: transparent;
    border-right: 1px solid #dedede;
}

hr {
    margin-top: 2px !important;
    margin-bottom: 20px !important;
}

#selected_course li {
    font-size: 0.89em;
}

#serviceAgreement {
    float: left;
    height: 300px;
    overflow: auto;
}

#serviceAgreement p {
    font-size: 0.81rem;
    text-align: justify;
}

.el-select-group__title {
    font-size: 14px !important;
    font-weight: bolder;
    color: #28c0e7;
}

.el-select-dropdown__item {
    font-size: 13px !important;
}

.reduceFont {
    font-weight: 400 !important;
}

.price-area .row {
    margin-top: 5px;
}

.bg-gradient-primary {
    background: linear-gradient(87deg, #07c9fb 0, #ffffff 100%) !important;
}

.req {
    color: red;
}

.knowMore-info {
    font-size: 13px;
    margin-left: 6px;
    text-decoration: underline;
    font-style: italic;
    color: #a8312d !important;
    cursor: pointer;
}

.price-info {
    font-size: 13px;
    font-weight: bold;
}

.priceGrid {
    border-bottom: 1px solid #dedede;
    padding-top: 8px;
}

.price-info {
    display: flex;
    align-items: center;
}

.provideScroller {
    padding-top: 10px;
    overflow-y: auto !important;
    max-height: 250px !important;
}

.login-inputs {
    padding-top: 20px;
}

.ctm-btn {
    background-color: #a8312d;
    color: #ffffff;
    border-radius: 0 !important;
    font-size: 17px;
    margin-right: 5px;
}

.login {
    margin-right: 0px !important;
}


.login-text {
    position: absolute !important;
}

@media only screen and (max-width: 1536px) {
    .registerFormBox {
        height: 250px;
    }

    .content-area {
        padding-bottom: 100px;
    }
}

@media (min-width: 992px) {
    .pt-lg-9,
    .py-lg-9 {
        padding-top: 3rem !important;
    }
}

.logo_img {
    width: 100px;
    padding: 10px;
}

.signup-text {
    position: absolute;
    right: 130px;
    top: 20px;
    z-index: 9;
    font-weight: 700;
}

@media screen and (max-width: 1280px) {
    .el-checkbox {
        white-space: normal;
        display: flex;
    }
}

.promoBTN {
    background: #008000;
    padding: 5px 15px;
    color: #fff;
    margin-bottom: 3px;
}

.promoBTN:hover {
    transform: translateY(0px);
    background: #008000;
    color: #fff;
}

.promoBTN_Minus {
    background: #a8312d;
    padding: 5px 15px;
    color: #fff;
    margin-bottom: 3px;
}

.promoBTN_Minus:hover {
    transform: translateY(0px);
    background: #a8312d;
    color: #fff;
}

.promo_inputBOX label {
    display: block;
}

</style>
